<template>
    <div class="view-trip-requests" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="tripRequestsLink">{{ translates.navigation.button_route_tripRequests[lang] }}</div>
        </nav>
        <h1 class="view-trip-requests__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
        <div class="view-trip-requests__content" v-if="loaded">
            <div v-if="tripRequests.length < 1" class="view-trip-requests__content__empty">{{ translates[langUrl].noRequests[lang] }}</div>
            <template v-for="(tripRequest, i) in tripRequests">
                <div
                    :key="'tripRequest' + i"
                    class="view-trip-requests__trip-request">
                    <div class="view-trip-requests__trip-request__detail__blogger">
                        <div class="view-trip-requests__trip-request__detail__blogger__title">
                            Имя Блогера
                        </div>
                        
                    </div>
                    <div class="view-trip-requests__trip-request__detail__columns">
                        <div class="view-trip-requests__trip-request__detail column">
                            <div class="view-trip-requests__trip-request__detail__key">Название тура</div>
                            <div class="view-trip-requests__trip-request__detail__value">Аляска с Хаски </div>
                        </div>
                        <div class="view-trip-requests__trip-request__detail column" v-if="tripRequest.direction">
                            <div class="view-trip-requests__trip-request__detail__key">Дата начала тура</div>
                            <div class="view-trip-requests__trip-request__detail__value">
                                15-19 фев. 2022
                            </div>
                        </div>
                        <div class="view-trip-requests__trip-request__detail column" v-if="tripRequest.directionAdditional">
                            <div class="view-trip-requests__trip-request__detail__key">Дата завершения тура</div>
                            <div class="view-trip-requests__trip-request__detail__value">
                                15-19 фев. 2022
                            </div>
                        </div>
                        <div class="view-trip-requests__trip-request__detail column">
                            <div class="view-trip-requests__trip-request__detail__key">Кол-во дней:</div>
                            <div class="view-trip-requests__trip-request__detail__value">
                                8 дней
                            </div>
                        </div>
                        <div class="view-trip-requests__trip-request__detail column">
                            <div class="view-trip-requests__trip-request__detail__key">Цена тура на чел.</div>
                            <div class="view-trip-requests__trip-request__detail__value">
                                80 000 ₽
                            </div>
                        </div>
                        <div class="view-trip-requests__trip-request__detail column">
                            <div class="view-trip-requests__trip-request__detail__key">Кол. участников</div>
                            <div class="view-trip-requests__trip-request__detail__value">
                                57
                            </div>
                            <!-- <div class="view-trip-requests__trip-request__detail__key">Начало</div>
                            <div class="view-trip-requests__trip-request__detail__value">
                                {{ tripRequest.start | moment('DD MMM YYYY') }}
                            </div> -->
                            <!-- <div class="view-trip-requests__trip-request__dates__start">
                                <div class="view-trip-requests__trip-request__dates__day">{{ tripRequest.start | moment('dddd') }}</div>
                                {{ tripRequest.start | moment('DD MMM YYYY') }}
                            </div> -->
                        </div>
                    </div>
                    <div class="view-trip-requests__trip-request__detail">
                        <div class="view-trip-requests__trip-request__detail__key">{{ translates[langUrl].activities[lang] }}</div>
                        <div class="view-trip-requests__trip-request__detail__value">
                            Арт и дизайн, Тренинг и фитнес, Экстремальный спорт
                        </div>
                    </div>
                    <div class="view-trip-requests__trip-request__detail__container">
                        <div class="view-trip-requests__trip-request__detail">
                            <div class="view-trip-requests__trip-request__detail__key">{{ translates[langUrl].workshops[lang] }}</div>
                            <div class="view-trip-requests__trip-request__detail__value">
                                Хотелось бы кофе и чай
                            </div>
                        </div>
                        <!-- <div class="view-trip-requests__trip-request__price">
                            <div class="view-trip-requests__trip-request__price__value"><span v-html="priceFormated(tripRequest.price, tripRequest.currency)"></span> </div>
                        </div> -->
                        <BaseButton
                            class="button"
                            @click="chooseTrip(tripRequest)">{{ translates[langUrl].button_chooseTrip[lang] }}</BaseButton>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS } from '@/vars';
    import { priceFormated } from '@/helpers';
    import BaseButton from '../../components/common/BaseButton';

    export default {
        name: 'TripRequests',
        metaInfo: {
            title: 'Запросы на тур от блогеров'
        },
        components: {
            BaseButton
        },
        data: () => ({
            loaded: false,
            priceFormated,
            isDesktop: false,
            langUrl: '/operator/trip-requests'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('tripRequests', {
                tripRequests: state => state.entities
            }),
            ...mapState('quiz', ['dictionaries']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            // filteredDirections(tripRequest) {
            //     return this.dictionaries.directions.filter(item => {
            //         for(const d of this.summary.directions) {
            //             if (d.code.toString() === item.code.toString()){
            //                 return false;
            //             }
            //         }
            //         return true;
            //     });
            // }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            await store.dispatch('tripRequests/dictionaries');
            await store.dispatch('tripRequests/fetchOrganizer');
            await store.dispatch('quiz/dictionaries');
            this.loaded = true;
        },
        methods: {
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async chooseTrip(tripRequest) {
                await this.$router.push({ 
                    name: 'operator-blogger-trip-choose',
                    params: { tripRequestId: tripRequest._id }
                });
            },
            formattedDirections(value) {
                return value && this.dictionaries.directions ? this.dictionaries.directions.find(item => (item.code.toString() === value.toString())).title : '';
            },
            formattedDirectionsAdditional(value) {
                return value && this.dictionaries.directionsAdditional ? this.dictionaries.directionsAdditional.find(item => (item.code.toString() === value.toString())).title : '';
            },
            formattedComfort(value) {
                return value && this.dictionaries.comforts ? this.dictionaries.comforts.find(item => (item.code.toString() === value.toString())).title : '';
            },
            activities(tripRequest) {
                if(this.dictionaries.activities) {
                    return tripRequest.activities.map(item => {
                        return this.dictionaries.activities.find(a => a.code.toString() === item.toString()).title;
                    }).join(', ');
                }
                return '';
            },
            lastDigitToWord (digit) {
                const lastFigure = parseInt(digit % 10);
                if (digit >= 11 && digit < 15) {
                    return 'дней';
                }
                else {
                    if (lastFigure == 1) return 'день';
                    if (lastFigure > 1 && lastFigure < 5) return 'дня';
                    if (lastFigure == 0 || lastFigure >= 5) return 'дней';
                }
                return lastFigure;
            },
            async tripRequestsLink () {
                await this.$router.push({ name: 'operator-trip-requests' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
        }
    }
</script>

<style lang="scss">
    .view-trip-requests {
        @media all and (min-width: 768px) {
            padding: 0 32px;
        }
        @media all and (min-width:1464px) {
            padding: 0;
        }
        &__menu{
            margin: 13px 24px 0;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            @media all and (min-width:768px) {
                margin: 13px 0 0;
            }
        }
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            
            @media all and (min-width: 768px) {
                flex-direction: row;
                margin-right: -32px;
            }

            &__empty {
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
                text-align: center;
                margin: 10vw auto;
            }
        }
        &__trip-request{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 16px 24px 24px;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-bottom: 32px;
            color: #3A4256;

            @media all and (max-width: 768px) {
                width: calc(100% - 40px);
                margin: 0 auto 32px;
            }
            
            &-canceled {
                background: #dddddd;
            }
            &__canceled {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 12px !important;
                border: 1px solid #3A4256 !important;
                margin-bottom: 5px;
                height: 48px;
                align-self: center;
                background: none;
                font-size: 14px;
                line-height: 140%;
            }

            &__trip {
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.02em;
                margin-bottom: 14px;
            }
            &__user {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.02em;
                margin-bottom: 14px;
            }
            &__dates {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                padding: 16px 0;
                border-top: 1px solid #F6F7F9;
                border-bottom: 1px solid #F6F7F9;
                &__day {
                    font-size: 14px;
                    line-height: 140%;
                    color: #9FA5AD;
                }
            }
            &__price {
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;
                margin-top: 24px;
            }
            &__info {
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                margin: 12px 0 24px;
            }

            &__detail {
                display: flex;
                flex-direction: column;
                padding: 20px 0;
                &:first-of-type{
                    padding-top: 0;
                }
                &__blogger {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    &__title {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 150%;
                        margin-bottom: 8px;
                        color: #3A4256;
                        padding: 0 20px 0 0;
                        margin: auto 0;
                    }
                    @media all and (max-width: 768px) {
                        padding: 20px 0;
                        width: 100%;
                        flex-direction: column;
                        border-left: none;
                        border-bottom: none;
                    }
                }
                &__columns {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-left: -20px;
                    @media all and (max-width: 768px) {
                        margin-left: 0;
                    }
                }
                &.column {
                    padding: 0 20px;
                    margin: 10px 0;
                    border-left: 1px #EDEDED solid;
                    &:first-of-type {
                        border: none;
                    }
                    @media all and (max-width: 768px) {
                        padding: 0 0 20px;
                        width: 100%;
                        border-left: none;
                        border-bottom: 1px #EDEDED solid;
                        &:first-of-type {
                            margin-right: 0;
                            border-bottom: 1px #EDEDED solid;
                        }
                    }
                }
                
                &__key {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    margin-bottom: 8px;
                    color: #A1A4B1;
                }
                &__value {
                    font-weight: 600;
                    font-size: 18px;
                    .comment {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #A1A4B1;

                    }
                }
                &__container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    @media all and (max-width: 768px) {
                        flex-direction: column;
                    }
                }
            }
            button {
                width: 220px;
                // margin: 0 auto 0 0;
            }
        }
    }
</style>